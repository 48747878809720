import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'waitinglistweb'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, {
  params: merge(criteria, pagination)
  })
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const CleanPresetClassVacancy = () => SocialProjectManager.get(`${resourceName}/clean-preset-class-vacancy`)
const Create = data => SocialProjectManager.post(resourceName, data)
const Transfer = data => SocialProjectManager.post(`${resourceName}/transfer`, data)
const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)
const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const WaitingListWebRepository = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  Transfer,
  CleanPresetClassVacancy
}

export default WaitingListWebRepository
